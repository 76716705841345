<template>
  <WidgetOverlayFullScreen
    v-if="item"
    class="listing-project-block__overlay"
    ref="blockOverlay"
  >
    <div class="listing-project-block__overlay__content">
      <!-- Close Button -->
      <div class="listing-project-block__overlay__close">
        <WidgetButtonCtaIco
          :isSmall="true"
          :buttonType="'close'"
          :label="$t('cta.close')"
          @click="animateOut"
        />
      </div>
      <WidgetVideo
        ref="blockOverlayVideo"
        class="listing-project-block__overlay__video is-cover"
        :videoUrl="item"
        :controls="true"
        :loop="false"
        :autoplay="false"
      />
      <div class="rotate-device-image">
        <img
          src="@/assets/img/ico/ico-rotate-white.svg"
          width="58.29"
          height="55.049"
          :alt="$t('cta.rotateDevice')"
        />
      </div>
    </div>
  </WidgetOverlayFullScreen>
</template>

<script>
export default {
  name: 'ListingProjectBlock',
}
</script>

<script setup>
const item = useState('quickview')

const blockOverlay = ref(null)
const blockOverlayVideo = ref(null)
const durationSlow = 1

watch(
  () => item.value,
  async (item) => {
    if (item) {
      await nextTick()
      await animateIn()
    }
  }
)

const animateIn = async () => {
  await blockOverlay.value.animateIn().duration(durationSlow).play()
  blockOverlayVideo.value.videoElement.play()
}

const animateOut = async () => {
  blockOverlayVideo.value.videoElement.pause()
  await blockOverlay.value.animateOut().duration(durationSlow).play()
  item.value = null
}
</script>

<style lang="scss">
@import './style.scss';
</style>
